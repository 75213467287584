import React from 'react';
import ContactForm from './ContactForm';
import logo from './logo.png';
import './App.css';

const App = () => {

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <div className="App-body">
        <div>
          <p>Introducing Orenco Labs, a cutting-edge technology company harnessing the power of the AI landscape to drive rapid business innovation.</p>
          <p>Simply share your requirements, and we will get back to you with complete working solutions.</p>
        </div>
      </div>
      <div>
        <ContactForm />
      </div>
    </div >
  );
};

export default App;
