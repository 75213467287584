import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import './ContactForm.css';

const ContactForm = () => {
    const [state, handleSubmit] = useForm('mknlgakg');


    if (state.succeeded) {
        setTimeout(() => {
            window.location.reload();
        }, 5000); // refresh page after 5 seconds
        return <p className="success-message">Thank you. We will get back to you shortly.</p>;
    }

    return (
        <form onSubmit={handleSubmit} className="contact-form">
            <div className='form-group'>
                <input
                    id="email"
                    type="email"
                    name="email"
                    required
                    placeholder="Enter your email address"
                    className={state.errors.email ? 'error' : ''}
                />
                <ValidationError prefix="Email" field="email" errors={state.errors} />

            </div>
            <div className='form-group'>
                <textarea
                    id="message"
                    name="message"
                    placeholder="Describe your requirement or leave a comment and we'll get back to you."
                    className={state.errors.message ? 'error' : ''}
                />
                <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                />
            </div>
            <div className='form-group'>
                <button type="submit" disabled={state.submitting}>
                    {state.submitting ? 'Submitting...' : 'Submit'}
                </button>
            </div>
        </form >
    );
};

export default ContactForm;
